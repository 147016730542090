<template>
    <div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="bitselect" v-model="selectedValue" value="istrue"
                :id="id">
            <label class="form-check-label" :for="id">
                {{ filterItem.getOperatorTitle('istrue') }}
            </label>
        </div>

        <div class="form-check">
            <input class="form-check-input" type="radio" name="bitselect" v-model="selectedValue" value="isfalse"
                :id="id + '1'">
            <label class="form-check-label" :for="id + '1'">
                {{ filterItem.getOperatorTitle('isfalse') }}
            </label>
        </div>

        <div class="form-check">
            <input class="form-check-input" type="radio" name="bitselect" v-model="selectedValue" value="isfalse_or_not_exists_clause"
                :id="id + '2'">
            <label class="form-check-label" :for="id + '2'">
                {{ $t('Is false or not exists') }}
            </label>
        </div>

    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    filterItem: null,
    filterObject: null,
    dropdown: null,
    hideOperators: null
});

const selectedValue = computed({
    get() {
        if (props.filterItem.operator == 'inlist') {
            return 'isfalse_or_not_exists_clause'
        } else {
            return props.filterItem.selectedValue;
        }
    },
    set(value) {
        switch (value) {
            case 'istrue':
            case 'isfalse':
                props.filterItem.selectedValue = value;
                break;
            case 'isfalse_or_not_exists_clause':
                props.filterItem.operator = 'inlist';
                props.filterItem.selectedValue = [0, '[-unallocated-]'];
                break;
        }
    }
});

const id = `checkboxFilter-${crypto.randomUUID()}`;
</script>